import React, {Fragment} from "react"
import {useDispatch} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {Row, Col, Button, Input, UncontrolledTooltip} from "reactstrap"
import {useTable, useSortBy, useFilters, useExpanded, usePagination} from 'react-table'
import {Edit, Sort, ArrowDropUp, ArrowDropDown, DoneAll, RemoveDone, UploadFile} from '@mui/icons-material';
import Switch from "react-switch";
import {deleteCompany} from "../../store/actions/company";
import DeleteButton from "../../components/Common/CustomButtons/DeleteButton";
import LeafIcon from "../../components/Common/Leaf/leafIcon";
import {helperFormatDate} from "../../helpers/main";

import "./index.scss"

function Filter ({ column }) {
  return (
    <div style={{ marginTop: 5 }}>
      {column.canFilter && column.render("Filter")}
    </div>
  )
}

function DefaultColumnFilter ({column: { Header, filterValue, setFilter, preFilteredRows: { length }}}) {
  return (
    <Input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={Header}
    />
  );
};

// function SelectStatus ({column: { filterValue, setFilter, preFilteredRows, id } }) {
//   return (
//     <select value={filterValue}  onChange={e => {setFilter(e.target.value || '')}} className="header_filter_select">
//       <option value={' '}>{i18n.t('plan_page.all')}</option>
//       <option value={'true '}> {i18n.t('company_page.connected')}</option>
//       <option value={'false '}> {i18n.t('company_page.disconnect')}</option>
//     </select>
//   )
// }

function TableR({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: { pageIndex: 0, pageSize: 25 },
      autoResetExpanded: false,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? <ArrowDropDown/> : <ArrowDropUp/>) : <Sort/>;
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  const { t } = useTranslation();


  // Render the UI for your table
  return (
    <Fragment>
      <table {...getTableProps()} className="table table-striped table-hover">
        <thead className="table-light">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps({
                  style: { minWidth: column.minWidth },
                  className: column.className,
                })}>
                  <div {...column.getSortByToggleProps()}>
                    {column.render("Header")}
                    {!column.hideSorting && generateSortingIndicator(column)}
                  </div>
                  <Filter column={column} />
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row)
            return (
              <Fragment key={row.getRowProps().key}>
                <tr>{row.cells.map(cell => <td {...cell.getCellProps()}>{cell.render("Cell")}</td>)}</tr>
              </Fragment>
            )
          })}
        </tbody>
      </table>

      <Row className="pagination_row">
        <Col md={3}>
          <Button color="primary" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{"<<"}</Button> {' '}
          <Button color="primary" onClick={previousPage} disabled={!canPreviousPage}>{"<"}</Button>
        </Col>
        <Col md={2} style={{ marginTop: 7 }}>
          t('page') <strong> {pageIndex + 1} af {pageOptions.length}</strong>
        </Col>
        <Col md={2}>
          <Input type="number" min={1} style={{ width: 70 }} max={pageOptions.length} defaultValue={pageIndex + 1} onChange={onChangeInInput}/>
        </Col>
        <Col md={2}>
          <select value={pageSize} onChange={onChangeInSelect} className="select_filter">
            {[25, 50, 100].map(pageSize => ( <option key={pageSize} value={pageSize}> Sýna {pageSize} </option>))}
          </select>
        </Col>
        <Col md={3}>
          <Button color="primary" onClick={nextPage} disabled={!canNextPage}>{">"}</Button> {' '}
          <Button color="primary" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{">>"}</Button>
        </Col>
      </Row>
    </Fragment>
  )
}

const CompanyTable = (props) => {
  const dispatch = useDispatch ();
  const { t } = useTranslation();
  const userdata = JSON.parse(localStorage.getItem('p_user'));
  const permission = userdata ? userdata.permission : []
  const canEdit = permission.includes('admin_companies_edit');
  const canDisableEnable = permission.includes('admin_companies_disable_enable');
  const leafColors = [
    {id: "Umhverfi", color: "#4FC878", secondColor: "#A7EEBF"},
    {id: "Félagslegir þættir", color: "#FD974F", secondColor: "#FFCEAC"},
    {id: "Stjórnarhættir", color: "#4433CD", secondColor: "#E3C9FC"}
  ];

  const columns = React.useMemo(
    () => [
      {
        Header: '#',
        hideSorting: true,
        disableFilters: true,
        Cell: ({ cell }) => {
          return parseInt(cell.row.id)+1
        },
      },
      {
        Header: t('user_page.logo'),
        disableFilters: true,
        disableSortBy: true,
        className: 'logo_c',
        accessor: (values) => {
          let logo = values.logo ? values.logo : "";
          return <div style={{ backgroundImage: "url("+ logo +")" }} className="img-thumbnail rounded-circle img_item" />
        },
      },
      {
        Header: t('company_page.name'),
        accessor: 'name',
        Cell: ({ cell }) => {
          const {row} = cell;
          return row.original.link ? <a href={row.original.link} target="_blank" rel="noopener noreferrer" class="company_url_link">{row.original.name}</a> : row.original.name
        },
      },
      {
        Header: 'Kennitala',
        accessor: 'kennitala',
      },
      {
        Header: t('pluses'),
        disableFilters: true,
        accessor: (values) => {
          let val = Object.values(values.green_step_category_count);
          return val[0]+val[1]+val[2];
        },
        Cell: ({ cell }) => {
          const {row} = cell;
          let temp = row.original.green_step_category_count;
          let a = [];
          let i=0;
          for (const [key, value] of Object.entries(temp)) {
            a.push(
              <React.Fragment key={"leaf_item_frag"+row.original.id+"_"+key}>
                <LeafIcon
                  key={"leaf_item"+row.original.id+"_"+i}
                  id={'leaf_tooltip_'+row.original.id+"_"+i}
                  className="leaf-icon"
                  colors={leafColors.find(color => color.id === key)}
                  percentage={value > 0 ? value/100 : 0}
                />
                <UncontrolledTooltip placement="bottom" autohide={false} className="leaf_tooltip" target={'leaf_tooltip_'+row.original.id+"_"+i}>
                  {key}: {value}%
                </UncontrolledTooltip>
              </React.Fragment>
            );
            i++;
          }

          return <div className="company_leaf_items">
            {a}
          </div>
        },
      },
      {
        Header: 'Plan',
        accessor: (values) => {
          return values.plan ? values.plan.name : ''
        }
      },
      {
        Header: 'Municipality',
        accessor: (values) => {
          return values.municipality ? values.municipality.name : ''
        }
      },
      {
        Header: t('company_page.created'),
        disableFilters: true,
        accessor: (values) => {
          return new Date(values.created_at)
        },
        Cell: ({ cell }) => {
          const {value} = cell;
          let a = new Date('1970-01-01');
          return value.getTime() === a.getTime() ? '' : helperFormatDate(value)
        },
        sortType: 'datetime'
      },
      {
        Header: t('company_page.visible'),
        disableFilters: true,
        accessor: (values) => {
          return values.visible
        },
        Cell: ({ cell }) => {
          const {value} = cell;
          return <Switch
            key={cell.row.index+'switch'}
            uncheckedIcon={<div className="swithStyle">{" "}</div>}
            checkedIcon={<div className="swithStyle">{" "}</div>}
            onColor="#009369"
            className="form-label leaf_item_checkbox"
            onChange={() => props.confirmConnected(value, cell.row?.original)}
            checked={value}
          />
        },
        // Filter: SelectStatus,
        // filter: 'includes',
      },

      {
        Header: ' ',
        id: 'expander',
        className: 'action_c',
        Cell: ({ row }) => {
          return(
            <React.Fragment>
              {canEdit && <Button color="success" id="tooltip_company_export" key={row.original.id+'_export'} size="sm" onClick={() => props.exprtCompany(row.original)} style={{margin: '5px 0 0 5px'}}>
                <UploadFile style={{fontSize: '14px'}}/>
                <UncontrolledTooltip placement="bottom" target={'tooltip_company_export'}>Export</UncontrolledTooltip>
              </Button>}
              {canDisableEnable ?
                row.original.disabled ?
                  <Button color="success" id="tooltip_company_enable" key={row.original.id+'_enable'} style={{margin: '5px 0 0 5px'}} size="sm" onClick={() => props.confirmDisableEnableCompany('enable', row.original)}>
                    <DoneAll style={{fontSize: '14px'}}/>
                    <UncontrolledTooltip placement="bottom" target={'tooltip_company_enable'}>{t('enable_company')}</UncontrolledTooltip>
                  </Button>
                  :
                  <Button color="danger" id="tooltip_company_disable" key={row.original.id+'_disable'} style={{margin: '5px 0 0 5px'}} size="sm" onClick={() => props.confirmDisableEnableCompany('disable', row.original)}>
                    <RemoveDone style={{fontSize: '14px'}}/>
                    <UncontrolledTooltip placement="bottom" target={'tooltip_company_disable'}>{t('disable_company')}</UncontrolledTooltip>
                  </Button>
                : ''}
              {canDisableEnable && <DeleteButton id="tooltip_company_delete" tooltip={true} key={row.original.id+'_delete'} action={() => dispatch(deleteCompany(row.original))}  styleBtn={{margin: '5px 0 0 5px'}} />}
              {canEdit && <Button color="success" id="tooltip_company_edit" key={row.original.id+'_edit'} size="sm" onClick={() => props.editCompany(row.original)} style={{margin: '5px 0 0 5px'}}>
                <Edit style={{fontSize: '14px'}}/>
                <UncontrolledTooltip placement="bottom" target={'tooltip_company_edit'}>{t('edit')}</UncontrolledTooltip>
              </Button>}
            </React.Fragment>
          )
        },
      },

    ],
    []
  );

  return (
     <TableR
        columns={columns}
        data={props.data}
      />
  )
};

export default CompanyTable
